<script setup lang="ts">
const { locale, locales } = useI18n()

const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return locales.value.filter((loc) => loc.code !== locale.value)
})
</script>

<template>
  <NuxtLink
    v-for="loc in availableLocales"
    :key="loc.code"
    class="border-b border-neutral-400 border-b-transparent font-bold uppercase no-underline hover:border-b-black"
    :to="switchLocalePath(loc.code)"
  >
    {{ loc.code }}
  </NuxtLink>
</template>

<style scoped></style>
